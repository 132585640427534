import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Context
import {
  PageTitleProvider,
  PageTitleConsumer,
} from "../context/page-title-context";

import {
  PageTypeProvider,
  PageTypeConsumer,
} from "../context/page-type-context";

// Components
import Header from "./header";

const Site = styled.div`
  cursor: url(/icons/${props => props.cursor}@1x.png) 12 12, pointer !important;
  cursor: -webkit-image-set(
        url(/icons/${props => props.cursor}@1x.png) 1x,
        url(/icons/${props => props.cursor}@2x.png) 2x
      )
      12 12,
    pointer !important;
`;

const Main = styled.main`
  flex: 1;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: ${props => (props.location === "/" ? "0" : "0 10px")};
`;

const App = ({ location, data, children }) => {
  const [cursor, setCursor] = useState(`cursor-white`);

  useEffect(() => {
    if (location === "/") {
      setCursor(`cursor-white`);
    } else {
      setCursor(`cursor-green`);
    }
  }, [location]);

  return (
    <PageTitleProvider>
      <PageTitleConsumer>
        {({ pageTitle }) => (
          <PageTypeProvider>
            <PageTypeConsumer>
              {({ pageType }) => (
                <Site cursor={cursor}>
                  <Header
                    location={location}
                    pageTitle={pageTitle}
                    pageType={pageType}
                  />
                  <Main location={location}>{children}</Main>
                </Site>
              )}
            </PageTypeConsumer>
          </PageTypeProvider>
        )}
      </PageTitleConsumer>
    </PageTitleProvider>
  );
};

export default App;
