import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Media from "react-media";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 10px;

  background-color: ${props => props.background};
  z-index: 200;

  font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif;

  font-size: 18px;
  line-height: 1;

  letter-spacing: 0.03em;

  color: ${props => props.textColor};
`;

const Header = ({ location, data, pageTitle, pageType }) => {
  const [windowWidth, setWindowWidth] = useState(768);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  useEffect(() => {
    console.log(
      "%cWebsite Development: Kieran Startup",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);
  return (
    <HeaderWrapper
      background={location === "/" ? `none` : `#fff`}
      textColor={location === "/" ? `#fff` : `#000`}
    >
      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => (
          <MobileMenu
            links={data.site.siteMetadata.menuLinks}
            projectLinks={data.prismicHomepage.data.projects}
            location={location}
            pageTitle={pageTitle}
            pageType={pageType}
          />
        )}
      />

      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => (
          <DesktopMenu
            links={data.site.siteMetadata.menuLinks}
            projectLinks={data.prismicHomepage.data.projects}
            location={location}
            pageTitle={pageTitle}
            pageType={pageType}
          />
        )}
      />
    </HeaderWrapper>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
        prismicHomepage {
          data {
            projects {
              project {
                document {
                  ... on PrismicProjectStrand {
                    id
                    uid
                    data {
                      title {
                        html
                      }
                      projects {
                        project {
                          document {
                            ... on PrismicProject {
                              uid
                              data {
                                title {
                                  html
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);
