import React, { createContext } from "react";

const PageType = createContext({
  pageType: "",
  setPageType: () => {},
});

export class PageTypeProvider extends React.Component {
  setPageType = newPageType => {
    this.setState({ pageType: newPageType });
  };

  state = {
    pageType: "",
    setPageType: this.setPageType,
  };

  render() {
    return (
      <PageType.Provider value={this.state}>
        {this.props.children}
      </PageType.Provider>
    );
  }
}

export const PageTypeConsumer = PageType.Consumer;
