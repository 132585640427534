import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const DefaultSEO = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      prismicSeo {
        data {
          seo_description {
            text
          }
          seo_keywords {
            text
          }
          seo_image {
            url
          }
        }
      }
    }
  `);

  return (
    <Helmet
      title={`Olivia Plender`}
      meta={[
        {
          name: "viewport",
          content:
            "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
        },
        {
          name: "title",
          content: `Olivia Plender`,
        },
        {
          property: "og:title",
          content: `Olivia Plender`,
        },
        {
          property: "og:type",
          content: `website`,
        },
        {
          property: "og:url",
          content: location.href,
        },
        {
          property: "twitter:url",
          content: location.href,
        },
        {
          name: "description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "keywords",
          content: `${data.prismicSeo.data.seo_keywords.text}`,
        },
        {
          property: "og:image",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          property: "og:image:secure_url",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          property: "og:description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          property: "og:image:width",
          content: `1200`,
        },
        {
          property: "og:image:height",
          content: `630`,
        },
        {
          property: "og:locale",
          content: `en`,
        },
        {
          property: "twitter:title",
          content: `Olivia Plender`,
        },
        {
          property: "twitter:description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          property: "twitter:card",
          content: `summary_large_image`,
        },
        {
          property: "twitter:image",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
      ]}
      link={[
        {
          rel: "dns-prefetch",
          href: "https://images.prismic.io/oliviaplender",
        },
        {
          rel: "preconnect",
          href: "https://images.prismic.io/oliviaplender",
        },
        {
          rel: "dns-prefetch",
          href: "https://player.vimeo.com",
        },
        {
          rel: "preconnect",
          href: "https://player.vimeo.com",
        },
        {
          rel: "dns-prefetch",
          href: "https://i.vimeocdn.com",
        },
        {
          rel: "preconnect",
          href: "https://i.vimeocdn.com",
        },
        {
          rel: "dns-prefetch",
          href: "https://f.vimeocdn.com",
        },
        {
          rel: "preconnect",
          href: "https://f.vimeocdn.com",
        },
        {
          rel: "preload",
          href: "/fonts/fonts.css",
          as: "style",
        },
        {
          rel: "preload",
          href: "/fonts/ExcelsiorLT.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/ExcelsiorLT.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/ExcelsiorLT-Italic.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/ExcelsiorLT-Italic.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/UntitledSans.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/UntitledSans.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/UntitledSansItalic.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/UntitledSansItalic.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
      ]}
    />
  );
};
