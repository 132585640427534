import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import "../styles/plyr.css";

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;

  font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif;
`;

// async function getVimeoThumbnail(video, width, height) {
//   let response = await fetch(
//     `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${video}&width=${width}&height=${height}&quality=1080`
//   );
//   let data = await response.json();
//   return data;
// }

export const Video = ({ video, height, width, playerId }) => {
  // const [isThumbnailVisible, setIsThumbnailVisible] = useState(true);
  // const [vimeoThumbnail, setThumbnail] = useState(null);

  // let video_id = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
  //   match = embed_url.match(video_id);

  // let loadingVimeoThumbnail = getVimeoThumbnail(match[7], width, height);

  // loadingVimeoThumbnail.then(result => {
  //   setThumbnail(result.thumbnail_url);
  // });

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      const player = new Plyr(`#plyr_id_${playerId}`, {
        autoplay: false,
        muted: false,
        loop: { active: true },
        hideControls: false,
        vimeo: {
          autopause: true,
          byline: false,
          controls: false,
          referrerPolicy: "strict-origin-when-cross-origin",
        },
      });

      // player.on("progress", event => {
      //   const instance = event.detail.plyr;
      //   setIsThumbnailVisible(instance.buffered >= 2);
      // });
    }
  }, []);

  return (
    <VideoWrapper className="video" height={height} width={width}>
      {/* {isThumbnailVisible && <Thumbnail src={vimeoThumbnail} loading="lazy" />} */}

      <div
        className="plyr__video-embed js-player"
        id={`plyr_id_${playerId}`}
        dangerouslySetInnerHTML={{
          __html: video,
        }}
      />
    </VideoWrapper>
  );
};
