import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";

// SEO
import { PageSeo } from "../components/seo/seo";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageTypeConsumer } from "../components/context/page-type-context";

// Icons
import { Line } from "../components/icons/line";

const Page = styled.div`
  margin: 160px 0 0 0;

  width: 100%;

  @media (max-width: 768px) {
    margin: 75px 0 0 0;
  }

  & a {
    transition: 150ms color ease;

    &:hover {
      color: #3b9c62;
    }
  }
`;

const Title = styled.div`
  font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif;

  font-style: normal;
  font-weight: normal;

  & h1,
  & h2 {
    font-size: 18px;
    line-height: 24px;

    letter-spacing: 0.03em;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const Text = styled.div`
  font-family: "ExcelsiorLT", Georgia, serif;

  font-size: 17px;
  line-height: 26px;

  letter-spacing: 0.05em;

  & p:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const LineContainer = styled.div`
  height: 1px;
  margin: 100px 0;

  @media (max-width: 768px) {
    margin: 60px 0;
  }
`;

const Article = styled.article`
  width: 100%;

  & .mobile-title {
    display: none;
  }

  &:last-of-type {
    & .line {
      background-image: none;
      margin: 100px 0 0 0;
    }
  }

  & img {
    &.portrait {
      padding: 0 15%;
    }

    @media (max-width: 768px) {
      margin: 30px 0;
    }
  }

  @media (max-width: 768px) {
    & .desktop-title {
      display: none;
    }
    & .mobile-title {
      display: block;
    }
  }
`;

const NewsContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("news");
  }, []);

  const content = data.prismicNews.data.articles.map((content, index) => (
    <Article key={`news_article_${index}`}>
      <Col col={12}>
        <Row>
          <Col col={12}>
            <Title
              className="mobile-title"
              dangerouslySetInnerHTML={{
                __html: content.article.document.data.title.html,
              }}
            />
            <Title
              className="mobile-title"
              dangerouslySetInnerHTML={{
                __html: content.article.document.data.subtitle.html,
              }}
            />
          </Col>

          <Col col={12} md={4} mdOffset={1}>
            {content.article.document.data.images[0].image.fluid !== null && (
              <img
                className={
                  content.article.document.data.images[0].image.dimensions
                    .width >
                  content.article.document.data.images[0].image.dimensions
                    .height
                    ? `landscape`
                    : `portrait`
                }
                srcSet={
                  content.article.document.data.images[0].image.fluid.srcSetWebp
                }
                src={
                  content.article.document.data.images[0].image.fluid.srcWebp
                }
                alt={content.article.document.data.images[0].image.alt}
                loading="lazy"
              />
            )}
          </Col>

          <Col col={12} md={5} mdOffset={1}>
            <Title
              className="desktop-title"
              dangerouslySetInnerHTML={{
                __html: content.article.document.data.title.html,
              }}
            />
            <Title
              className="desktop-title"
              dangerouslySetInnerHTML={{
                __html: content.article.document.data.subtitle.html,
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: content.article.document.data.text.html,
              }}
            />
          </Col>
        </Row>
      </Col>

      <Col col={12}>
        <LineContainer>
          <Line className="line" />
        </LineContainer>
      </Col>
    </Article>
  ));

  return (
    <>
      <PageSeo
        seoTitle={`News`}
        seoText={``}
        seoImage={``}
        canonical={`/news/`}
      />
      <Page>
        <Row>{content}</Row>
      </Page>
    </>
  );
};

const News = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => <NewsContent setPageType={setPageType} data={data} />}
  </PageTypeConsumer>
);

export const query = graphql`
  {
    prismicNews {
      _previewable
      data {
        articles {
          article {
            document {
              ... on PrismicNewsArticle {
                id
                data {
                  title {
                    html
                  }
                  subtitle {
                    html
                  }
                  text {
                    html
                  }
                  images {
                    image {
                      alt
                      fluid {
                        srcSetWebp
                        srcWebp
                      }
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(News);
