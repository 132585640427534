import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";

import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageTitleConsumer } from "../components/context/page-title-context";
import { PageTypeConsumer } from "../components/context/page-type-context";

// Components
import { Video } from "../components/videos/video";
import { Audio } from "../components/audio/audio";

// Icons
import { Line } from "../components/icons/line";

const Page = styled.div`
  margin: 190px 0 0 0;

  width: 100%;

  @media (max-width: 768px) {
    margin: 75px 0 0 0;
  }

  & a {
    transition: 150ms color ease;

    &:hover {
      color: #3b9c62;
    }
  }
`;

const TagsContainer = styled.div`
  font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif;

  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;

  max-width: 700px;

  & span {
    &::after {
      content: ", ";
    }
    &:last-of-type {
      &::after {
        content: "";
      }
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

const ContentContainer = styled.section`
  & .content-container {
    margin: 0 0 170px 0;

    @media (max-width: 768px) {
      margin: 0 0 60px 0;
    }
  }
`;

const Text = styled.div`
  font-family: "ExcelsiorLT", Georgia, serif;

  font-size: 17px;
  line-height: 26px;

  letter-spacing: 0.05em;

  white-space: pre-wrap;

  max-width: 720px;
  margin: 0 0 0 auto;

  & p:first-of-type {
    margin-top: 0;
  }

  & strong {
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 768px) {
      font-size: 13px;
      line-height: 18px;
    }

    color: #000;

    & em {
      font-family: "UntitledSansItalic", -apple-system, BlinkMacSystemFont,
        "Segoe UI", Helvetica, Arial, sans-serif;
      font-style: normal;
    }
  }

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const TextWrapper = styled.div`
  & .button-container {
    max-width: 720px;
    margin: 0 0 0 auto;

    text-align: center;

    & button {
      margin: 0 auto;

      font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Helvetica, Arial, sans-serif;
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

const TruncatedText = styled(Text)`
  overflow: hidden;
  & .fade {
    height: ${props => props.height};
    position: relative;
  }
  & .fade:after {
    content: "";
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 5.2em;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 75%
    );
    opacity: ${props => props.opacity};
  }
`;

const Caption = styled.div`
  font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif;

  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.03em;
  margin: 1em 0 0 0;

  visibility: ${props => props.visible};

  & em {
    font-family: "UntitledSansItalic";
  }

  & p {
    margin: 0;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  @media (hover: none) and (pointer: coarse) {
    // display: none;
    visibility: visible;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.03em;

    &.image-with-text-caption {
      margin: 0 0 60px 0;
    }
  }
`;

const FurtherReadingContainer = styled.div`
  padding: 20px 0 140px 0;
  margin: 0;

  @media (max-width: 768px) {
    padding: 20px 0 60px 0;
  }

  & .inner-reading-container {
    padding: 0 8.333333333333334%;

    @media (max-width: 768px) {
      padding: 0;

      & h3 {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  & .further-reading-links-container {
    padding: 90px 0 0 0;

    @media (max-width: 768px) {
      padding: 60px 0 0 0;
    }
  }

  & .futher-reading-links {
    padding: 0 0 25px 0;

    & .link-title {
      padding: 0 20px 0 0;

      display: inline-block;
      position: relative;
    }

    & a {
      display: inline-block;
      position: relative;
    }

    &:last-of-type {
      padding: 0;
    }
  }

  & p {
    margin: 0;
  }

  & .link-container {
    font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;

    font-size: 18px;
    line-height: 26px;

    letter-spacing: 0.03em;

    text-align: center;
  }
`;

const ProjectLinksContainer = styled.div`
  width: 100%;
  padding: 50px 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding: 25px 0;
  }
`;

const PrevProject = styled.div`
  text-align: left;
  width: 60px;

  & a {
    font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;

    font-size: 18px;
    line-height: 26px;

    letter-spacing: 0.03em;
  }

  @media (max-width: 768px) {
    & a {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const Spacer = styled.div`
  width: 60px;
`;

const NextProject = styled.div`
  flex: 2;
  text-align: center;

  & a {
    font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;

    font-size: 18px;
    line-height: 26px;

    letter-spacing: 0.03em;
  }

  @media (max-width: 768px) {
    & a {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const ProjectContent = ({ data, setPageTitle, setPageType }) => {
  const [isCaptionVisible, setCaptionVisible] = useState(null);
  const [activeExpandedText, setActiveExpandedText] = useState(null);

  const toggleCaption = id => {
    setCaptionVisible(id);
  };

  useEffect(() => {
    setPageTitle(data.prismicProject.data.title.html);
    setPageType("project");
  }, []);

  const tags =
    data.prismicProject.data.tags !== undefined &&
    data.prismicProject.data.tags.length >= 1
      ? data.prismicProject.data.tags
          .filter(tag => tag.tag.document !== undefined)
          .filter(tag => tag.tag.document !== null)
          .map((tag, index) => (
            <span key={tag.tag.document.id}>
              <Link
                to={`/index-page`}
                state={{
                  tag: tag.tag.document,
                }}
              >
                {tag.tag.document.data.title.text}
              </Link>
            </span>
          ))
      : null;

  const content = data.prismicProject.data.body.map((content, index) => {
    if (content.slice_type === "image_with_text") {
      return (
        <Row key={content.id} className="content-container">
          <Col col={12} md={4} mdOffset={1}>
            {content.primary.image.fluid !== null && (
              <img
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
                onMouseEnter={() => toggleCaption(content.id)}
                onClick={() => toggleCaption(content.id)}
                loading="lazy"
              />
            )}

            <Caption
              className="image-with-text-caption"
              visible={isCaptionVisible === content.id ? `visible` : `hidden`}
              dangerouslySetInnerHTML={{
                __html: content.primary.image_caption.html,
              }}
            />
          </Col>

          <Col col={12} md={5} mdOffset={1}>
            <Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.primary.text.html,
                }}
              />

              {index === 0 && tags.length >= 1 && (
                <TagsContainer>
                  <p>Tags: {tags}</p>
                </TagsContainer>
              )}
            </Text>
          </Col>
        </Row>
      );
    }

    if (content.slice_type === "image_right") {
      let offset;

      if (content.primary.pinned_image_side === "Right") {
        offset = 3;
      }

      if (content.primary.pinned_image_side === "Left") {
        offset = 1;
      }

      const isPortrait =
        content.primary.image.fluid !== null &&
        content.primary.image.dimensions !== undefined
          ? content.primary.image.dimensions.height >
            content.primary.image.dimensions.width
          : false;

      if (isPortrait === true) {
        return (
          <Row key={content.id} className="content-container">
            <Col col={12} md={6} mdOffset={offset}>
              {content.primary.image.fluid !== null && (
                <img
                  srcSet={content.primary.image.fluid.srcSetWebp}
                  src={content.primary.image.fluid.srcWebp}
                  alt={content.primary.image.alt}
                  onMouseEnter={() => toggleCaption(content.id)}
                  onClick={() => toggleCaption(content.id)}
                  loading="lazy"
                />
              )}
              <Caption
                visible={isCaptionVisible === content.id ? `visible` : `hidden`}
                dangerouslySetInnerHTML={{
                  __html: content.primary.image_caption.html,
                }}
              />
              {index === 0 && tags.length >= 1 && (
                <TagsContainer>
                  <p>Tags: {tags}</p>
                </TagsContainer>
              )}
            </Col>
          </Row>
        );
      } else {
        return (
          <Row key={content.id} className="content-container">
            <Col col={12} md={8} mdOffset={offset}>
              {content.primary.image.fluid !== null && (
                <img
                  srcSet={content.primary.image.fluid.srcSetWebp}
                  src={content.primary.image.fluid.srcWebp}
                  alt={content.primary.image.alt}
                  onMouseEnter={() => toggleCaption(content.id)}
                  onClick={() => toggleCaption(content.id)}
                  loading="lazy"
                />
              )}
              <Caption
                visible={isCaptionVisible === content.id ? `visible` : `hidden`}
                dangerouslySetInnerHTML={{
                  __html: content.primary.image_caption.html,
                }}
              />
              {index === 0 && tags.length >= 1 && (
                <TagsContainer>
                  <p>Tags: {tags}</p>
                </TagsContainer>
              )}
            </Col>
          </Row>
        );
      }
    }

    if (content.slice_type === "full_width_image") {
      return (
        <Row key={content.id} className="content-container">
          <Col col={12}>
            {content.primary.image.fluid !== null && (
              <img
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
                onMouseEnter={() => toggleCaption(content.id)}
                onClick={() => toggleCaption(content.id)}
                loading="lazy"
              />
            )}
            <Caption
              visible={isCaptionVisible === content.id ? `visible` : `hidden`}
              dangerouslySetInnerHTML={{
                __html: content.primary.image_caption.html,
              }}
            />
            {index === 0 && tags.length >= 1 && (
              <TagsContainer>
                <p>Tags: {tags}</p>
              </TagsContainer>
            )}
          </Col>
        </Row>
      );
    }

    if (content.slice_type === "two_images_right") {
      let offset;

      if (content.primary.pinned_image_side === "Right") {
        offset = 1;
      }

      if (content.primary.pinned_image_side === "Left") {
        offset = 0;
      }

      const images = content.items.map((image, index) => {
        let imageId = `${content.id}_${index}`;

        const isPortrait =
          image.image.fluid !== null && image.image.dimensions !== undefined
            ? image.image.dimensions.height > image.image.dimensions.width
            : false;

        if (isPortrait === true) {
          return (
            <Col
              col={6}
              md={4}
              key={`two_images_${content.id}_${index}`}
              mdOffset={index === 0 ? offset : 1}
            >
              {image.image.fluid !== null && (
                <img
                  srcSet={image.image.fluid.srcSetWebp}
                  src={image.image.fluid.srcWebp}
                  alt={image.image.alt}
                  onMouseEnter={() => toggleCaption(imageId)}
                  onClick={() => toggleCaption(imageId)}
                  loading="lazy"
                />
              )}
              <Caption
                visible={isCaptionVisible === imageId ? `visible` : `hidden`}
                dangerouslySetInnerHTML={{
                  __html: image.image_caption.html,
                }}
              />
            </Col>
          );
        } else {
          return (
            <Col
              col={6}
              md={5}
              key={`two_images_${content.id}_${index}`}
              mdOffset={index === 0 ? offset : 1}
            >
              {image.image.fluid !== null && (
                <img
                  srcSet={image.image.fluid.srcSetWebp}
                  src={image.image.fluid.srcWebp}
                  alt={image.image.alt}
                  onMouseEnter={() => toggleCaption(imageId)}
                  onClick={() => toggleCaption(imageId)}
                  loading="lazy"
                />
              )}
              <Caption
                visible={isCaptionVisible === imageId ? `visible` : `hidden`}
                dangerouslySetInnerHTML={{
                  __html: image.image_caption.html,
                }}
              />
            </Col>
          );
        }
      });

      return (
        <Row key={content.id} className="content-container">
          {images}

          <Col col={12}>
            {index === 0 && tags.length >= 1 && (
              <TagsContainer>
                <p>Tags: {tags}</p>
              </TagsContainer>
            )}
          </Col>
        </Row>
      );
    }

    if (content.slice_type === "video") {
      return (
        <Row key={content.id} className="content-container">
          <Col col={12} md={8} mdOffset={2}>
            <Video
              video={content.primary.video.html}
              height={content.primary.video.height}
              width={content.primary.video.width}
              playerId={content.id}
              onMouseEnter={() => toggleCaption(content.id)}
              onClick={() => toggleCaption(content.id)}
            />

            <Caption
              visible={isCaptionVisible === content.id ? `visible` : `hidden`}
              dangerouslySetInnerHTML={{
                __html: content.primary.caption.html,
              }}
            />

            {index === 0 && tags.length >= 1 && (
              <TagsContainer>
                <p>Tags: {tags}</p>
              </TagsContainer>
            )}
          </Col>
        </Row>
      );
    }

    if (content.slice_type === "audio") {
      return (
        <Row key={content.id} className="content-container">
          <Col col={12} md={10} mdOffset={1}>
            <Audio url={content.primary.audio_file.url} playerId={content.id} />

            <Caption
              visible={`visible`}
              dangerouslySetInnerHTML={{
                __html: content.primary.caption.html,
              }}
            />

            {index === 0 && tags.length >= 1 && (
              <TagsContainer>
                <p>Tags: {tags}</p>
              </TagsContainer>
            )}
          </Col>
        </Row>
      );
    }

    if (content.slice_type === "text") {
      if (content.primary.text.text.length >= 3500) {
        return (
          <Row key={content.id} className="content-container">
            <Col col={12} md={8} mdOffset={3}>
              <TextWrapper>
                <TruncatedText
                  opacity={activeExpandedText === content.id ? 0 : 1}
                  height={activeExpandedText === content.id ? `auto` : `60.2em`}
                >
                  <div
                    className="fade"
                    dangerouslySetInnerHTML={{
                      __html: content.primary.text.html,
                    }}
                  />
                </TruncatedText>
                <div className="button-container">
                  <button
                    onClick={
                      activeExpandedText === content.id
                        ? () => setActiveExpandedText(null)
                        : () => setActiveExpandedText(content.id)
                    }
                  >
                    {activeExpandedText === content.id
                      ? `(Read Less)`
                      : `(Keep Reading)`}
                  </button>
                </div>
              </TextWrapper>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row key={content.id} className="content-container">
            <Col col={12} md={3} style={{ alignSelf: `flex-end` }}>
              {index === 0 && tags.length >= 1 && (
                <TagsContainer>
                  <p>Tags: {tags}</p>
                </TagsContainer>
              )}
            </Col>
            <Col col={12} md={8}>
              <Text
                dangerouslySetInnerHTML={{
                  __html: content.primary.text.html,
                }}
              />
            </Col>
          </Row>
        );
      }
    }
  });

  const furtherReading = data.prismicProject.data.links
    .filter(link => link.link.url !== "")
    .map((link, index) => (
      <div
        className="futher-reading-links"
        key={`further_reading_link_${index}`}
      >
        <span
          className="link-title"
          dangerouslySetInnerHTML={{
            __html: link.link_title.html,
          }}
        />

        <a href={link.link.url} target="_blank" rel="noreferrer noopener">
          ({link.link_call_to_action})
        </a>
      </div>
    ));

  const currentProjectUrl = data.prismicProject.uid;
  const currentStrandUrl =
    data.prismicProject.data.project_strand.document !== null &&
    data.prismicProject.data.project_strand.document !== undefined
      ? data.prismicProject.data.project_strand.document.uid
      : ``;
  const allProjectLinks =
    data.prismicProject.data.project_strand.document !== null &&
    data.prismicProject.data.project_strand.document !== undefined
      ? data.prismicProject.data.project_strand.document.data.projects.map(
          content => content.project.uid
        )
      : null;

  const currentProjectUrlPosition =
    allProjectLinks !== null ? allProjectLinks.indexOf(currentProjectUrl) : ``;

  const nextProjectLink =
    allProjectLinks !== null
      ? allProjectLinks[currentProjectUrlPosition + 1] !== undefined
        ? allProjectLinks[currentProjectUrlPosition + 1]
        : allProjectLinks[0]
      : ``;

  const prevProjectLink =
    allProjectLinks !== null
      ? allProjectLinks[currentProjectUrlPosition - 1] !== undefined
        ? allProjectLinks[currentProjectUrlPosition - 1]
        : allProjectLinks[0]
      : ``;

  return (
    <>
      <PageSeo
        canonical={`/${currentStrandUrl}/${data.prismicProject.uid}/`}
        seoTitle={data.prismicProject.data.title.text}
        seoText={data.prismicProject.data.subtitle.text}
        seoImage={
          data.prismicProject.data.thumbnail.fluid !== null
            ? data.prismicProject.data.thumbnail.fluid.src
            : ``
        }
      />

      <Page>
        <ContentContainer>{content}</ContentContainer>

        {furtherReading.length >= 1 && (
          <>
            <Line />

            <FurtherReadingContainer>
              <div className="inner-reading-container">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.prismicProject.data.further_reading_title.html,
                  }}
                />
                <div className="further-reading-links-container">
                  {furtherReading}
                </div>
              </div>
            </FurtherReadingContainer>
          </>
        )}

        <Line />
        <ProjectLinksContainer>
          <PrevProject>
            <Link to={`/${currentStrandUrl}/${prevProjectLink}`}>(Back)</Link>
          </PrevProject>

          <NextProject>
            <Link to={`/${currentStrandUrl}/${nextProjectLink}`}>
              (Next Project)
            </Link>
          </NextProject>

          <Spacer />
        </ProjectLinksContainer>
      </Page>
    </>
  );
};

const Project = ({ data }) => (
  <PageTitleConsumer>
    {({ setPageTitle }) => (
      <PageTypeConsumer>
        {({ setPageType }) => (
          <ProjectContent
            setPageTitle={setPageTitle}
            setPageType={setPageType}
            data={data}
          />
        )}
      </PageTypeConsumer>
    )}
  </PageTitleConsumer>
);

export const query = graphql`
  query Project($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      url
      _previewable
      data {
        title {
          html
          text
        }
        thumbnail {
          fluid {
            src
          }
        }
        subtitle {
          text
        }
        project_strand {
          document {
            ... on PrismicProjectStrand {
              id
              uid
              data {
                projects {
                  project {
                    uid
                  }
                }
              }
            }
          }
        }
        tags {
          tag {
            document {
              ... on PrismicTag {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicProjectDataBodyImageWithText {
            id
            slice_type
            primary {
              image {
                alt
                dimensions {
                  width
                  height
                }
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
              text {
                html
              }
              image_caption {
                html
              }
            }
          }
          ... on PrismicProjectDataBodyImageRight {
            id
            slice_type
            primary {
              pinned_image_side
              image {
                dimensions {
                  width
                  height
                }
                fluid {
                  srcSetWebp
                  srcWebp
                }
                alt
              }
              image_caption {
                html
              }
            }
          }
          ... on PrismicProjectDataBodyFullWidthImage {
            id
            slice_type
            primary {
              image {
                alt
                dimensions {
                  width
                  height
                }
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
              image_caption {
                html
              }
            }
          }
          ... on PrismicProjectDataBodyTwoImagesRight {
            id
            slice_type
            primary {
              pinned_image_side
            }
            items {
              image {
                alt
                dimensions {
                  width
                  height
                }
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
              image_caption {
                html
              }
            }
          }
          ... on PrismicProjectDataBodyAudio {
            id
            slice_type
            primary {
              audio_file {
                url
              }
              caption {
                html
              }
            }
          }
          ... on PrismicProjectDataBodyVideo {
            id
            slice_type
            primary {
              does_video_have_sound
              caption {
                html
              }
              video {
                embed_url
                html
                width
                height
              }
            }
          }
          ... on PrismicProjectDataBodyText {
            id
            slice_type
            primary {
              text {
                html
                text
              }
            }
          }
        }
        further_reading_title {
          html
        }
        links {
          link {
            url
          }
          link_title {
            html
          }
          link_call_to_action
        }
      }
    }
  }
`;

export default withPrismicPreview(Project);
