import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const ProjectsContainer = styled.ol`
  padding: 100px 0;
`;

const Project = styled.li`
  width: 100%;
  padding: 14px 0;

  text-align: center;

  & h1,
  & .bracket {
    font-family: "ExcelsiorLT", Georgia, serif;

    font-size: 41px;
    line-height: 49px;

    letter-spacing: 0.07em;

    color: #000;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 26px;
    }

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & a {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  & .bracket {
    opacity: 0;
  }

  &:hover {
    & .bracket {
      opacity: 1;
    }
  }
`;

export const StrandNavigation = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          projects {
            project {
              document {
                ... on PrismicProjectStrand {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.prismicHomepage.data.projects
    .filter(
      content => !location.pathname.includes(content.project.document.uid)
    )
    .map((content, index) => (
      <Project key={`project_${index}`}>
        <Link to={`/${content.project.document.uid}`}>
          <h1>
            <span className="bracket">(</span>
            {content.project.document.data.title.text}
            <span className="bracket">)</span>
          </h1>
        </Link>
      </Project>
    ));

  return <ProjectsContainer>{content}</ProjectsContainer>;
};
