import React from "react";
import styled from "styled-components";

const Icon = styled.div`
  position: relative;

  & svg {
    position: absolute;
    left: -10px;
    right: 0;

    width: 100vw;
  }
`;

export const Line = () => (
  <Icon>
    <svg
      width="100%"
      height="1"
      // viewBox="0 0 100 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        strokeDasharray="10, 10"
        x1="0"
        y1="1"
        x2="100%"
        y2="1"
        strokeWidth="10"
        stroke="#000"
      />
    </svg>
  </Icon>
);
