import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  position: relative;
`;

export const MenuClose = () => (
  <Icon
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 22.2L22.2 1" stroke="black" strokeMiterlimit="10" />
    <path d="M22.2 22.2L1 1" stroke="black" strokeMiterlimit="10" />
  </Icon>
);
