import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
  body {
    opacity: 0;
    transition: 100ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body{
    opacity: 1;
  }
}

body {
  font-family: "ExcelsiorLT", Georgia, serif;
  font-weight: normal;
  font-style: normal;

  font-size: 17px;
  line-height: 26px;

  letter-spacing: 0.05em;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  word-break: break-word;
  overflow-x: hidden;

  @media(max-width:768px){
    font-size: 13px;
    line-height: 18px;
  }
}

p:empty{
  display: inline-block;
}

em{
  font-family: "ExcelsiorLT-Italic", Georgia, serif;;
  font-style: normal;
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5,strong{
  font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  font-weight: normal;

  margin: 0;
}

strong {
  font-style:normal;
  color: #3b9c62;

  em{
    font-family: "UntitledSansItalic", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-style: normal;
  }
}



/* Title 1 */
h1{
  font-size: 18px;
  line-height: 26px;

  letter-spacing: 0.03em;
}

/* Title 2 */
h2 {
  font-size: 18px;
  line-height: 26px;

  letter-spacing: 0.03em;
}

/* Heading 1 */
h3 {
  font-size: 18px;
  line-height: 26px;

  letter-spacing: 0.03em;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 26px;

  letter-spacing: 0.03em;
}

/* Heading 3 */
h5 {
  font-size: 18px;
  line-height: 26px;

  letter-spacing: 0.03em;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border: 0;

    cursor: inherit;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
  cursor: inherit;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

/*--------------------------------------------------------------
  ## Lists
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

iframe{
  width: 100%;
}
`;

export default GlobalStyle;
