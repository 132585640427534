import React from "react";
import styled from "styled-components";

const AspectRatio = styled.div`
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => props.aspect};
  }

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const AspectRatioImage = ({ src, srcSet, alt, ar, loading }) => (
  <AspectRatio aspect={ar}>
    <div className="content">
      <img
        srcSet={srcSet}
        src={src}
        alt={alt}
        loading={loading ? loading : `lazy`}
      />
    </div>
  </AspectRatio>
);
