import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageTitleConsumer } from "../components/context/page-title-context";
import { PageTypeConsumer } from "../components/context/page-type-context";

// Components
import { AspectRatioImage } from "../components/images/custom-aspect-ratio";
import { StrandNavigation } from "../components/strand/strand-navigation";

const Page = styled.div`
  margin: 40px 0 0 0;

  width: 100%;
`;

const Title = styled.div`
  margin: 16px 0 0 0;

  & h1 {
    font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;

    font-size: 18px;
    line-height: 24px;

    letter-spacing: 0.03em;

    color: #000000;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

const Subtitle = styled.div`
  & h2 {
    font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;

    font-size: 18px;
    line-height: 24px;

    letter-spacing: 0.03em;

    text-align: center;

    color: #000000;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  margin: 0 0 160px 0;

  @media (max-width: 768px) {
    margin: 60px 0;

    & h2 {
      text-align: left;
    }
  }
`;

const Project = styled.div`
  margin: 0 0 0 0;

  & .subtitle h3 {
    font-family: "ExcelsiorLT", Georgia, serif;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.05em;

    margin: 3px 0 0 0;
    opacity: 0;

    @media (hover: none) and (pointer: coarse) {
      opacity: 1;
    }
  }

  &:hover {
    & .subtitle h3 {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.desktopColumns};
  grid-column-gap: ${props => props.desktopColumnGap};
  grid-row-gap: ${props => props.desktopRowGap};

  @media (max-width: 768px) {
    grid-template-columns: ${props => props.mobileColumns};
    grid-column-gap: ${props => props.mobileColumnGap};
    grid-row-gap: ${props => props.mobileRowGap};
  }
`;

const StrandContent = ({ data, setPageTitle, setPageType, location }) => {
  useEffect(() => {
    setPageTitle(data.prismicProjectStrand.data.title.html);
    setPageType("strand");
  }, []);

  const content = data.prismicProjectStrand.data.projects
    .filter(project => project.project.document !== null)
    .map((content, index) => {
      return (
        <Project key={`project_${content.project.document.id}_${index}`}>
          <Link
            to={`/${data.prismicProjectStrand.uid}/${content.project.document.uid}`}
          >
            {content.project.document.data.thumbnail.fluid !== null && (
              <AspectRatioImage
                srcSet={
                  content.project.document.data.thumbnail.fluid.srcSetWebp
                }
                src={content.project.document.data.thumbnail.fluid.srcWebp}
                alt={content.project.document.data.thumbnail.alt}
                width={4}
                height={3}
                ar={`75%`}
              />
            )}
            <Title
              dangerouslySetInnerHTML={{
                __html: content.project.document.data.title.html,
              }}
            />

            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: content.project.document.data.subtitle.html,
              }}
            />
          </Link>
        </Project>
      );
    });

  return (
    <>
      <PageSeo
        canonical={`/${data.prismicProjectStrand.uid}/`}
        seoTitle={data.prismicProjectStrand.data.title.text}
        seoText={data.prismicProjectStrand.data.subtitle.text}
        seoImage={``}
      />
      <Page>
        <Row>
          <Col col={12}>
            <Subtitle
              dangerouslySetInnerHTML={{
                __html: data.prismicProjectStrand.data.subtitle.html,
              }}
            />
          </Col>
        </Row>
        <Grid
          desktopColumns={`1fr 1fr 1fr`}
          mobileColumns={`1fr 1fr`}
          desktopColumnGap={`60px`}
          mobileColumnGap={`10px`}
          desktopRowGap={`110px`}
          mobileRowGap={`60px`}
        >
          {content}
        </Grid>

        <StrandNavigation location={location} />
      </Page>
    </>
  );
};

const Strand = ({ data, location }) => (
  <PageTitleConsumer>
    {({ setPageTitle }) => (
      <PageTypeConsumer>
        {({ setPageType }) => (
          <StrandContent
            setPageTitle={setPageTitle}
            setPageType={setPageType}
            data={data}
            location={location}
          />
        )}
      </PageTypeConsumer>
    )}
  </PageTitleConsumer>
);

export const query = graphql`
  query ProjectStrand($uid: String!) {
    prismicProjectStrand(uid: { eq: $uid }) {
      uid
      url
      _previewable
      data {
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                uid
                data {
                  title {
                    html
                  }
                  subtitle {
                    html
                  }
                  thumbnail {
                    alt
                    fluid(imgixParams: { ar: "4:3" }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default withPrismicPreview(Strand);
