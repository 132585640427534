import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageTypeConsumer } from "../components/context/page-type-context";

const Page = styled.div`
  padding: 40px 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 100vh;
`;

const BackgroundImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  object-fit: cover;
  object-position: center;

  z-index: -1;
`;

const ProjectsContainer = styled.ol``;

const Project = styled.li`
  width: 100%;
  padding: 14px 0;

  text-align: center;

  & h1,
  & .bracket {
    font-family: "ExcelsiorLT", Georgia, serif;

    font-size: 41px;
    line-height: 49px;

    letter-spacing: 0.07em;

    color: #fff;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 26px;
    }

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & a {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  & .bracket {
    opacity: 0;
  }

  &:hover {
    & .bracket {
      opacity: 1;
    }
  }
`;

const IndexContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("home");
  }, []);

  const defaultImage =
    data.prismicHomepage.data.projects[0].project.document.data.thumbnail.fluid;

  const [activeBackgroundImage, setActiveBackgroundImage] = useState(
    defaultImage
  );

  const allBgImages = data.prismicHomepage.data.projects.map(
    content => content.project.document.data.thumbnail.fluid
  );

  const content = data.prismicHomepage.data.projects.map((content, index) => (
    <Project
      key={`project_${index}`}
      onMouseEnter={() => setActiveBackgroundImage(allBgImages[index])}
    >
      <Link to={`/${content.project.document.uid}`}>
        <h1>
          <span className="bracket">(</span>
          {content.project.document.data.title.text}
          <span className="bracket">)</span>
        </h1>
      </Link>
    </Project>
  ));

  const preloadedSrcWebpImages = allBgImages.map((image, index) => (
    <link
      rel="preload"
      imagesrcset={image.srcWebp}
      as="image"
      key={`preloaded_image_${index}`}
    />
  ));

  const preloadedSrcSetWebpImages = allBgImages.map((image, index) => (
    <link
      rel="preload"
      href={image.srcSetWebp}
      as="image"
      key={`preloaded_image_${index}`}
    />
  ));

  return (
    <>
      <Helmet>
        {preloadedSrcWebpImages}
        {preloadedSrcSetWebpImages}
      </Helmet>
      <Page>
        <ProjectsContainer>{content}</ProjectsContainer>

        <BackgroundImage
          srcSet={activeBackgroundImage.srcSetWebp}
          src={activeBackgroundImage.srcWebp}
        />
      </Page>
    </>
  );
};

const Index = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <IndexContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export const query = graphql`
  {
    prismicHomepage {
      _previewable
      data {
        projects {
          project {
            document {
              ... on PrismicProjectStrand {
                id
                uid
                data {
                  title {
                    text
                  }
                  thumbnail {
                    fluid {
                      srcSetWebp
                      srcWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Index);
