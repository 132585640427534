import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import "../styles/plyr.css";

const AudioWrapper = styled.div`
  width: 100%;
  position: relative;

  font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif;
`;

export const Audio = ({ url, playerId }) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      const player = new Plyr(`#plyr_id_${playerId}`, {
        autoplay: false,
        muted: false,
        loop: { active: false },
        hideControls: false,
        invertTime: false,
      });
    }
  }, []);

  return (
    <AudioWrapper className="audio">
      <audio
        id={`plyr_id_${playerId}`}
        controls
        data-plyr-config='{ "controls": ["play", "progress", "current-time", "duration"] }'
      >
        <source src={url} type="audio/mp3" />
      </audio>
    </AudioWrapper>
  );
};
