import React from "react";
import { Helmet } from "react-helmet";

export const PageSeo = ({ seoTitle, seoText, seoImage, canonical }) => (
  <Helmet
    title={`${seoTitle} – Olivia Plender`}
    meta={[
      {
        name: "title",
        content: `${seoTitle} – Olivia Plender`,
      },
      {
        property: "og:title",
        content: `${seoTitle} – Olivia Plender`,
      },
      {
        name: "description",
        content: `${seoText}`,
      },
      {
        property: "og:image",
        content: `${seoImage}`,
      },
      {
        property: "og:image:secure_url",
        content: `${seoImage}`,
      },
      {
        property: "og:description",
        content: `${seoText}`,
      },
      {
        property: "og:image:width",
        content: `1200`,
      },
      {
        property: "og:image:height",
        content: `630`,
      },
      {
        property: "og:locale",
        content: `en`,
      },
      {
        property: "twitter:title",
        content: `${seoTitle} – Olivia Plender`,
      },
      {
        property: "twitter:description",
        content: `${seoText}`,
      },
      {
        property: "twitter:card",
        content: `summary_large_image`,
      },
      {
        property: "twitter:image",
        content: `${seoImage}`,
      },
    ]}
  >
    <link rel="canonical" href={`https://oliviaplender.org${canonical}`} />
  </Helmet>
);
