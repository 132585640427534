exports.linkResolver = doc => {
  if (doc.type === "home") {
    return `/`;
  }

  // URL for a project type
  if (doc.type === "project") {
    if (
      doc.data?.project_strand !== undefined &&
      doc.data?.project_strand !== `undefined`
    ) {
      if (
        doc.data?.project_strand?.uid !== null &&
        doc.data?.project_strand?.uid !== undefined &&
        doc.data?.project_strand?.uid !== `undefined`
      ) {
        return `/${doc.data.project_strand.uid}/${doc.uid}/`;
      } else {
        return `/${doc.uid}/`;
      }
    } else {
      return `/${doc.uid}/`;
    }
  }

  if (doc.type === "project_strand") {
    return `/projects/${doc.uid}/`;
  }

  if (doc.type === "about") {
    return `/about/`;
  }

  if (doc.type === "news") {
    return `/news/`;
  }

  if (doc.type === "news_article") {
    return `/news/`;
  }

  return `/${doc.uid}/`;
};
