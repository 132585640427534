import React, { createContext } from "react";

const PageTitle = createContext({
  pageTitle: "",
  setPageTitle: () => {},
});

export class PageTitleProvider extends React.Component {
  setPageTitle = newPageTitle => {
    this.setState({ pageTitle: newPageTitle });
  };

  state = {
    pageTitle: "",
    setPageTitle: this.setPageTitle,
  };

  render() {
    return (
      <PageTitle.Provider value={this.state}>
        {this.props.children}
      </PageTitle.Provider>
    );
  }
}

export const PageTitleConsumer = PageTitle.Consumer;
