import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

// Compnents
import { MenuClose } from "../icons/menu-close";

const Nav = styled.nav`
  width: 100%;

  & ol.main-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    & .end {
      display: flex;
      justify-content: flex-end;

      width: 125px;
    }
  }

  & ol.flex {
    width: 50%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & .strand-title h1 {
    font-family: "ExcelsiorLT", Georgia, serif;

    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0.05em;
  }

  & .project-title h1 {
    font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;

    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;
  }
`;

const Toggle = styled.button`
  padding: 0;
`;

const Close = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  z-index: 200;
`;

const Menu = styled.div`
  display: ${props => props.display};
  overflow-y: auto;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  width: 490px;
  background-color: #fff;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");

  padding: 85px 25px 25px 50px;
  margin: -1px -1px -1px 0;

  z-index: 100;

  & li {
    & a {
      transition: 150ms color ease;

      &:hover {
        color: #3b9c62;
      }
    }
  }

  & ol.project-links {
    padding: 0 0 2em 0;

    & li {
      padding: 0 0 10px 0;
    }
  }

  & ol.menu-links {
    // & li:first-of-type {
    //   display: none;
    // }

    & li {
      padding: 0 0 1em 0;
    }

    & .project-title h1 {
      font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Helvetica, Arial, sans-serif;

      font-size: 18px;
      line-height: 26px;

      letter-spacing: 0.03em;
    }
  }
`;

const SubMenuContainer = styled.ol`
  display: ${props => props.isOpen};
  padding: 1em 0 0 2em;

  & .submenu-project-title h1 {
    font-family: "ExcelsiorLT", Georgia, serif;

    font-size: 17px;
    line-height: 26px;

    letter-spacing: 0.05em;
  }
`;

export const DesktopMenu = ({
  links,
  projectLinks,
  location,
  pageTitle,
  pageType,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  // 2. Initialise your ref and targetElement here
  const targetRef = useRef(null);

  useEffect(() => {
    setMenuOpen(false);
    enableBodyScroll(targetRef.current);
  }, [location]);

  useEffect(() => {
    if (isMenuOpen) {
      disableBodyScroll(targetRef.current);
    } else {
      enableBodyScroll(targetRef.current);
    }
  }, [isMenuOpen]);

  const menuLinks = links.map((link, index) => (
    <li key={`menu_link_${index}`}>
      <Link to={link.link}>{link.name}</Link>
    </li>
  ));

  const allProjectLinks = projectLinks.map((link, index) => {
    const subMenu = link.project.document.data.projects
      .filter(project => project.project.document !== null)
      .map((subMenuLink, index) => (
        <li
          key={`sub_menu_link_${subMenuLink.project.document.uid}_${index}`}
          className="submenu-project-title"
        >
          <Link
            to={`/${link.project.document.uid}/${subMenuLink.project.document.uid}`}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: subMenuLink.project.document.data.title.html,
              }}
            />
          </Link>
        </li>
      ));

    return (
      <li key={`project_menu_link_${index}`}>
        <span className="project-title">
          <Link to={`/${link.project.document.uid}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: link.project.document.data.title.html,
              }}
            />
          </Link>
        </span>

        {subMenu.length >= 1 &&
          (pageType === "project" || pageType === "strand") && (
            <SubMenuContainer
              className="sub-menu"
              isOpen={
                location.includes(link.project.document.uid) ? `block` : `none`
              }
            >
              {subMenu}
            </SubMenuContainer>
          )}
      </li>
    );
  });

  return (
    <Nav>
      <ol className="main-menu">
        <li className="end">
          <Link to={`/`}>Olivia Plender</Link>
        </li>

        {pageType === "index" && (
          <li>
            <span>Index</span>
          </li>
        )}

        {pageType === "news" && (
          <li>
            <span>News</span>
          </li>
        )}

        {pageType === "about" && (
          <li>
            <span>About</span>
          </li>
        )}

        {(pageType === "project" || pageType === "strand") && (
          <li>
            <span
              className={
                pageType === "project" ? "project-title" : "strand-title"
              }
              dangerouslySetInnerHTML={{
                __html: pageTitle,
              }}
            />
          </li>
        )}

        {location === "/" && <ol className="flex">{menuLinks}</ol>}
        {location !== "/" && (
          <>
            <li className="end">
              <Toggle onClick={() => setMenuOpen(!isMenuOpen)}>Menu</Toggle>
            </li>
            <Menu
              display={isMenuOpen === true ? `block` : `none`}
              ref={targetRef}
            >
              <Close onClick={() => setMenuOpen(!isMenuOpen)}>
                <MenuClose />
              </Close>
              <ol className="project-links">{allProjectLinks}</ol>
              <ol className="menu-links">{menuLinks}</ol>
            </Menu>
          </>
        )}
      </ol>
    </Nav>
  );
};
