import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageTypeConsumer } from "../components/context/page-type-context";

const Page = styled.div`
  margin: 160px 0 0 0;

  & a {
    transition: 150ms color ease;

    &:hover {
      color: #3b9c62;
    }
  }

  @media (max-width: 768px) {
    margin: 75px 0 0 0;
  }
`;

const Text = styled.div`
  font-family: "ExcelsiorLT", Georgia, serif;

  font-size: 17px;
  line-height: 26px;

  letter-spacing: 0.05em;

  & p:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }
`;

const CVDownload = styled.div`
  & p {
    font-family: "UntitledSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;

    margin: 0;
    text-align: center;
  }

  & .email {
    margin: 1em 0;
  }
`;

const FriendsListContainer = styled.div`
  max-width: 320px;
  margin: 0 auto;

  & .friend {
    margin: 0 0 30px 0;
  }

  @media (max-width: 768px) {
    margin: 70px 0 0 0;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const AboutPageContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("about");
  }, []);

  const friendsList = data.prismicAbout.data.friends_list.map(
    (image, index) => (
      <a
        key={`friends_list_item_${index}`}
        href={image.website.url}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="friend"
          srcSet={image.image.fluid.srcSetWebp}
          src={image.image.fluid.srcWebp}
          alt={image.image.alt}
        />
      </a>
    )
  );

  return (
    <>
      <PageSeo
        seoTitle={`About`}
        seoText={``}
        seoImage={``}
        canonical={`/about/`}
      />
      <Page>
        <Col col={12}>
          <Row>
            <Col col={12} md={6} lg={5} lgOffset={1}>
              <Text
                dangerouslySetInnerHTML={{
                  __html: data.prismicAbout.data.text.html,
                }}
              />

              <CVDownload>
                <p>
                  <a
                    href={data.prismicAbout.data.cv.url}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    (CV)
                  </a>
                </p>

                <p>
                  <a
                    href={data.prismicAbout.data.portrait.url}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    (Portrait)
                  </a>
                </p>

                <p>
                  <a
                    href={data.prismicAbout.data.instagram.url}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    (Instagram)
                  </a>
                </p>

                <div
                  className="email"
                  dangerouslySetInnerHTML={{
                    __html: data.prismicAbout.data.email.html,
                  }}
                />
              </CVDownload>
            </Col>
            <Col col={12} md={6} lg={5} lgOffset={1}>
              <FriendsListContainer>
                {data.prismicAbout.data.friends_title_image.fluid !== null && (
                  <img
                    className="friend"
                    srcSet={
                      data.prismicAbout.data.friends_title_image.fluid
                        .srcSetWebp
                    }
                    src={
                      data.prismicAbout.data.friends_title_image.fluid.srcWebp
                    }
                    alt={data.prismicAbout.data.friends_title_image.alt}
                  />
                )}
                {friendsList}
              </FriendsListContainer>
            </Col>
          </Row>
        </Col>
      </Page>
    </>
  );
};

const About = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <AboutPageContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPrismicPreview(About);

export const query = graphql`
  {
    prismicAbout {
      _previewable
      data {
        text {
          html
        }
        cv {
          url
        }
        portrait {
          url
        }
        instagram {
          url
        }
        email {
          html
        }
        friends_title_image {
          fluid {
            srcSetWebp
            srcWebp
          }
          alt
        }
        friends_list {
          image {
            alt
            fluid {
              srcSetWebp
              srcWebp
            }
          }
          website {
            url
          }
        }
      }
    }
  }
`;
